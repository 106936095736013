import { Switch } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import classNames from "lib/classNames";
import useField from "lib/useField";
import { Controller } from "react-hook-form";

export default function Checkbox({ control, errors, field, variant }) {
  const { name, disabled, required, requiredMessage } = useField(field);
  const title = `${field["#title"]} ${required ? "*" : ""}`;
  const hasError = errors[name] || false;
  const themes = {
    default: {
      border: "border-default",
      text: "text-default",
    },
    primary: {
      border: "border-primary",
      text: "text-primary",
    },
    institution: {
      border: "border-institution",
      text: "text-institution",
    },
    formation: {
      border: "border-formation",
      text: "text-formation",
    },
    internship: {
      border: "border-internship",
      text: "text-internship",
    },
    news: {
      border: "border-news",
      text: "text-news",
    },
    inscription: {
      border: "border-inscription",
      text: "text-inscription",
    },
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required && requiredMessage,
        disabled,
      }}
      render={({ field: { value, onChange } }) => (
        <Switch.Group>
          <div className="flex items-center gap-4">
            <Switch
              checked={value}
              onChange={onChange}
              className={classNames(
                "relative inline-flex p-1 transition-colors border",
                themes[variant].border
              )}
            >
              <CheckIcon
                className={classNames(
                  "h-4 w-4 transition-colors",
                  value ? themes[variant].text : "text-transparent"
                )}
              />
            </Switch>

            <Switch.Label className={`cursor-pointer text-default-light ${name=="autorize" && "!text-black"}`}>
              {title} 
            </Switch.Label>
          </div>

          {hasError && (
            <p className="text-sm text-red-500 mt-2 font-semibold">
              {errors[name].message}
            </p>
          )}
        </Switch.Group>
      )}
    />
  );
}
